import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

const Page = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 190px 100px 0 100px;

  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 110px 20px 0 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  max-width: 660px;

  margin: 0 auto;

  & img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  width: 100%;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  color: rgba(253, 252, 252, 0.8);

  & h1 {
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(253, 252, 252, 0.8);

    margin: 60px 0 30px 0;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  & p {
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    text-align: left;
    color: #fcfbfc;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 768px) {
    & p {
      text-align: center;
    }
  }
`;

const TextPageContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("textpage");
  }, []);

  return (
    <>
      <Helmet
        title={`${data.prismicTextPage.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicTextPage.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicTextPage.data.title.text} – Park Village`,
          },
        ]}
      />
      <Page>
        <Container>
          <ContentContainer>
            <Text
              dangerouslySetInnerHTML={{
                __html: data.prismicTextPage.data.title.html,
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: data.prismicTextPage.data.text.html,
              }}
            />
          </ContentContainer>
        </Container>
      </Page>
    </>
  );
};

const TextPage = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <TextPageContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(TextPage);

export const query = graphql`
  query TextPage($uid: String!) {
    prismicTextPage(uid: { eq: $uid }) {
      prismicId
      data {
        title {
          html
          text
        }
        text {
          html
        }
      }
    }
  }
`;
